@import "./assets/scss/variable";
* {
  box-sizing: border-box;
}
h2 {
  font-size: 180px;
  font-weight: 800;
  line-height: 175px;
}

h3 {
  font-size: 101px;
  line-height: 110px;
}
#awwwards {
  @media all and (max-width: 767px) {
    transform: translateY(-50%) scale(0.5) !important;

    right: -15px !important;
  }
}

h4 {
  font-size: 3.7vw;
  line-height: 5.5vw;
  font-weight: 500;
  letter-spacing: -2px;
  @media all and (max-width: 767px) {
    font-size: 28px;
    line-height: 50px;
    letter-spacing: -1px;
  }
}

.component-container {
  padding-left: 13vw;
  padding-right: 13vw;
  max-width: 112vw;
  @media all and (min-width: 1600px) {
    padding-left: 15vw;
    padding-right: 15vw;
  }
  @media all and (max-width: 991px) {
    padding-left: 12vw;
    padding-right: 12vw;
  }
  @media all and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  &.intro-header {
    @media all and (max-width: 767px) {
      padding-bottom: 0;
      position: relative;
    }
  }
}
// #WaterTexture {
//   position: fixed;
//   top: 0;
// }
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
h2.preloader-heading {
  position: fixed;
  color: #f35151;
  margin: 0 auto;
  text-align: center;
  font-size: 32px;
  line-height: 1;
  z-index: 999999999999999999;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.custom-load-wrapper {
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: #000000;
  z-index: 999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: -90px;
  }
  h2 {
    position: fixed;
    left: 0;
    right: 0;
    color: #f35151;
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    line-height: 1;
    top: 90%;
  }
  &.hide {
    transform: translateY(100%);
    transition: 0.35s ease-in-out all;
  }
}
.disableLink {
  @media all and (max-width: 991px) {
    // cursor: none;
    pointer-events: none;
  }
}
canvas#stage,
canvas#aboutstage,
canvas#footerAboutScene {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
canvas#stage {
  @media all and (max-width: 767px) {
    z-index: -5;
    opacity: 0;
  }
}
canvas#aboutstage {
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
}
.whole-app-wrapper {
  position: relative;
  z-index: 10;
}
main {
  position: relative;
}

#cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
  will-change: transform;
  // bottom: 0;
  // right: 0;
  // width: 100%;
  // height: 100%;
  @media all and (max-width: 991px) {
    display: none;
  }
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    display: none;
  }
}
.project-list-item {
  width: 50%;
}
.blobyBg {
  position: relative;

  .BlobMain {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
    transition: all 0.35s ease-in-out;
  }
}
.cursor__circle {
  height: 40px;
  margin-top: -50%;
  margin-left: -50%;
  visibility: visible;
  border-radius: 21px;
  border: solid 1px #191a1b;
  transition:
    opacity 0.1s cubic-bezier(0.25, 1, 0.5, 1),
    background-color 0.1s cubic-bezier(0.25, 1, 0.5, 1),
    border-color 0.1s cubic-bezier(0.25, 1, 0.5, 1),
    width 0.1s cubic-bezier(0.25, 1, 0.5, 1),
    height 0.1s cubic-bezier(0.25, 1, 0.5, 1);
}
.contact-newblob {
  width: 150px;
  height: 150px;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
  position: absolute;
  left: -75px;
  top: -75px;
  svg {
    &.mail-icon {
      width: 30px;
      height: 30px;
      transform: scale(0);
      transition: all 0.35s ease-in-out;
      display: none;
    }
    &.eye-icon {
      display: none;
    }
    &.linkedInSvg {
      display: none;
    }
  }
}
.awwward-newBlob,
.awwward-2xhonors-newBlob {
  width: 210px;
  height: 210px;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
  position: absolute;
  left: -105px;
  top: -105px;
  svg {
    width: 60px;
    transform: scale(0);
    transition: all 0.35s ease-in-out;
    display: none;
  }
}
.awwward-invision-newBlob {
  width: 210px;
  height: 210px;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
  position: absolute;
  left: -105px;
  top: -105px;
  svg {
    width: 60px;
    transform: scale(0);
    transition: all 0.35s ease-in-out;
    display: none;
  }
}
.awwward-2017-newBlob {
  width: 210px;
  height: 210px;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
  position: absolute;
  left: -105px;
  top: -105px;
  svg {
    width: 60px;
    transform: scale(0);
    transition: all 0.35s ease-in-out;
    display: none;
  }
}
.awwward-adp-newBlob {
  width: 210px;
  height: 210px;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
  position: absolute;
  left: -105px;
  top: -105px;
  svg {
    width: 60px;
    transform: scale(0);
    transition: all 0.35s ease-in-out;
    display: none;
  }
}
@media (hover: hover) and (pointer: fine) {
  .module-block.find-linkedin-section .contact-me {
    z-index: 99999;
  }

  #cursor.link .cursor__circle {
    opacity: 0;
  }
  .loader-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    background: #000000;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    color: #fff;
    justify-content: center;
  }

  #cursor {
    &.project-cursor {
      z-index: 0;
    }
    &.cursor-awwwards {
      .cursor__circle {
        display: none;
      }
      .awwward-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-awwwards2018 {
      .cursor__circle {
        display: none;
      }
      .awwward-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-awwwards2xHonors {
      .cursor__circle {
        display: none;
      }
      .awwward-2xhonors-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-awwwards2017 {
      .cursor__circle {
        display: none;
      }
      .awwward-2017-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-awwwards2019 {
      .cursor__circle {
        display: none;
      }
      .awwward-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-invision {
      .cursor__circle {
        display: none;
      }
      .awwward-invision-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }
    &.cursor-adp {
      .cursor__circle {
        display: none;
      }
      .awwward-adp-newBlob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 60px;
          z-index: 1;
          transform: scale(1);
          display: block;
        }
      }
    }

    &.contactMe {
      z-index: 0;
      .cursor__circle {
        display: none;
      }
      .contact-newblob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 30px;
          height: 30px;
          z-index: 1;
          transform: scale(1);
          &.mail-icon {
            display: block;
          }
          &.eye-icon,
          &.linkedInSvg {
            display: none;
          }
        }
      }
    }

    &.linkedIn {
      z-index: 0;
      .cursor__circle {
        display: none;
      }
      .contact-newblob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(1);
        svg {
          width: 30px;
          height: 30px;
          z-index: 1;
          transform: scale(1);
          &.mail-icon,
          &.eye-icon {
            display: none;
          }
          &.linkedInSvg {
            display: block;
          }
        }
      }
    }
    &.lets-chat {
      z-index: 0;
      .cursor__circle {
        display: none;
      }
      .contact-newblob {
        visibility: visible;
        display: flex;
        transition: all 0.35s ease-in-out;
        transform: scale(0.5);
        svg {
          width: 30px;
          height: 30px;
          z-index: 1;
          transform: scale(1);
          &.mail-icon {
            display: none;
          }
          &.eye-icon,
          &.linkedInSvg {
            display: none;
          }
        }
      }
    }
    &.footer-contact {
      .cursor__circle {
        display: none;
      }
    }
  }

  #cursor.mail-link {
    z-index: 0;

    svg {
      &.eye-icon {
        display: none;
      }
    }

    span {
      display: none;
    }
  }

  #cursor.cursor-awwwards,
  #cursor.cursor-invision,
  #cursor.cursor-adp,
  #cursor.cursor-awwwards2018,
  #cursor.cursor-awwwards2xHonors,
  #cursor.cursor-awwwards2019,
  #cursor.cursor-awwwards2017 {
    z-index: 0;
  }

  #cursor.mail-link .mail-blob,
  #cursor.project-link .mail-blob {
    opacity: 1;
  }

  #cursor.lets-chat .lets-chat-blob {
    opacity: 1;
  }

  .lets-chat-blob {
    margin-top: -98%;
    margin-left: -20%;
    z-index: -1;
    background-color: $primary-color;
    color: white;
    opacity: 0;
    transition: opacity 0.35s ease-out !important;
    -moz-transition: opacity 0.35s ease-out !important;
    -webkit-transition: opacity 0.35s ease-out !important;
    -o-transition: opacity 0.35s ease-out !important;
  }

  .mail-blob {
    margin-top: -50%;
    margin-left: -50%;
    z-index: -1;
    background-color: $primary-color;
    color: white;
    opacity: 0;
    transition: opacity 0.35s ease-out !important;
    -moz-transition: opacity 0.35s ease-out !important;
    -webkit-transition: opacity 0.35s ease-out !important;
    -o-transition: opacity 0.35s ease-out !important;

    svg {
      width: 30px;
    }
  }

  #cursor.subtle .cursor__circle {
    opacity: 1;
  }

  #cursor.overlay .cursor__circle {
    width: 48px;
    height: 48px;
    background-color: rgba(227, 222, 193, 0.08);
    border-color: transparent;
  }
}
.containerGeogy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
}

.tile__figure {
  margin: 0;
  padding: 0;
  width: 100%;
}

.tile__image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(-50%);
  }
}
