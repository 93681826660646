@import "../../assets/scss/variable";
.jury-section {
  position: relative;
  padding-top: 30vw;
  padding-bottom: 20vw;
  overflow: hidden;
  width: 100%;
  .hero-marquee {
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
    top: 8vw;
    @media all and (max-width: 767px) {
      top: 30px;
    }
  }
  .hero-marquee {
    width: 200%;
    overflow: hidden;
    --offset: 20%;
    --move-initial: calc(-25% + var(--offset));
    --move-final: calc(-50% + var(--offset));
    transform-origin: center;
    .marquee-doc-text {
      width: fit-content;
      display: flex;
      transform: translate3d(var(--move-initial), 0, 0);
      transition: all 0.35s ease-in-out;
      animation: marqueenew 25s linear infinite;
      -webkit-animation: marqueenew 25s linear infinite;
      -moz-animation: marqueenew 25s linear infinite;
      -o-animation: marqueenew 25s linear infinite;

      animation-play-state: running;
      @media all and (max-width: 767px) {
        animation: marqueenew 15s linear infinite;
        -webkit-animation: marqueenew 15s linear infinite;
        -moz-animation: marqueenew 15s linear infinite;
        -o-animation: marqueenew 15s linear infinite;
      }
      h5 {
        position: relative;
        font-size: 10vw;
        font-weight: 800;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #191a1b;
        -webkit-text-fill-color: #f0f0f0;
        margin: 0 15px;
        letter-spacing: -5px;
        @media all and (max-width: 991px) {
          letter-spacing: -3px;
        }
        @media all and (max-width: 767px) {
          font-size: 15vw;
          letter-spacing: -1px;
          -webkit-text-stroke-width: 1px;
        }
      }
    }
  }

  @media all and (max-width: 767px) {
    padding: 140px 0px 100px;
    background: #f0f0f0;
  }

  .jury-content {
    width: 100%;
    @media all and (max-width: 767px) {
      padding: 0px 20px;
    }
    .jury-awwwards {
      padding: 0 13vw;
      display: flex;
      justify-content: flex-end;
      @media all and (max-width: 767px) {
        padding: 0;
        justify-content: flex-start;
      }
      .jury-list {
        width: 44vw;
        @media all and (max-width: 991px) {
          width: 60vw;
        }
        @media all and (max-width: 767px) {
          width: 100%;
        }
        .awwward-tag {
          max-width: 510px;
          line-height: 1.8vw;
          margin-bottom: 10vh;
          @media all and (max-width: 991px) {
            font-size: 17px;
            line-height: 32px;
            margin-bottom: 30px;
          }
          @media all and (max-width: 767px) {
            font-size: 16px;
            line-height: 25px;
          }
        }
        .awward-name {
          font-size: 16px;
          display: block;
          text-align: left;
          font-weight: 500;
        }
      }
    }
  }

  .menu {
    padding: 0;

    position: relative;
    display: flex;
    flex-direction: column;
    text-align: right;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    counter-reset: menucounter;
  }

  .menu__item {
    flex: none;
    display: flex;
    justify-content: flex-end;
    position: relative;
    color: $primary-color;
    text-decoration: none;
    @media all and (max-width: 991px) {
      justify-content: initial;
    }
    @media all and (max-width: 767px) {
      margin-bottom: 30px;
    }
    &.title-selected {
      @media all and (max-width: 991px) {
        z-index: 1;
      }

      .menu__item-textinner {
        @media all and (max-width: 991px) {
          color: $third-color;
          text-decoration: underline;
        }
      }
      .awward-name {
        @media all and (max-width: 991px) {
          color: $third-color;
        }
      }
      .hover-reveal {
        @media all and (max-width: 991px) {
          opacity: 1 !important;
        }
        @media all and (max-width: 1024px) {
          transform: translate(-175px, -122.719px) !important;
          transition: all 0.35s ease-in-out;
        }
        @media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
          transform: translate(-175px, -122.719px) !important;
          transition: all 0.35s ease-in-out;
        }
        @media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          transform: translate(-175px, -122.719px) !important;
          transition: all 0.35s ease-in-out;
        }
        @media all and (max-width: 767px) {
          transform: translate(152px, -103.719px) scale(1) !important;
          transition: all 0.35s ease-in-out;
        }
      }
    }
  }

  .menu__item:hover::before {
    opacity: 1;
    transform: translateX(0);
  }

  .menu__item-text {
    position: relative;
    cursor: pointer;
    padding: 1.5vw 0;
    display: block;
    overflow: hidden;
    font-size: 6vw;
  }

  .menu__item-textinner {
    display: block;
    white-space: nowrap;
    font-size: 2vw;
    transition: text-shadow 0.3s;
    letter-spacing: -1px;
    @media all and (max-width: 991px) {
      font-size: 25px;
      line-height: 50px;
    }
    @media all and (max-width: 767px) {
      line-height: 35px;
    }
  }

  .js .menu__item-textinner {
    transform: translateY(100%);
  }

  .menu__item-sub {
    display: none;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    align-items: center;
    position: relative;
    margin-left: 2rem;
    padding-left: 3rem;
    opacity: 0;
    transform: translateX(-1rem);
    transition: transform 0.3s, opacity 0.3s;
  }

  .menu__item:hover .menu__item-sub {
    opacity: 1;
    transform: translateX(0);
  }
  .menu__item:hover {
    text-decoration: underline;
    color: $third-color;
  }
  .menu__item-sub::before {
    content: "";
    position: absolute;
    left: 0;
    top: 15%;
    width: 1px;
    height: 70%;
    background: currentColor;
    transform-origin: 0 100%;
    transform: rotate(22.5deg) scale3d(1, 0, 1);
    transition: transform 0.3s;
  }

  .menu__item:hover .menu__item-sub::before {
    transform: rotate(22.5deg) scale3d(1, 1, 1);
  }

  .hover-reveal {
    position: absolute;
    z-index: -1;
    width: 220px;
    height: 320px;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;

    @media all and (max-width: 1024px) {
      transform: translate(-225px, -122.719px) !important;
      transition: all 0.35s ease-in-out;
    }
    @media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      transform: translate(-225px, -122.719px) !important;
      transition: all 0.35s ease-in-out;
    }
    @media all and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      transform: translate(-225px, -122.719px) !important;
      transition: all 0.35s ease-in-out;
    }
    @media all and (max-width: 767px) {
      transform: translate(152px, -103.719px) scale(0.5) !important;
      transition: all 0.35s ease-in-out;
      width: 180px;
      height: 262px;
    }
  }

  .hover-reveal__inner {
    overflow: hidden;
    @media all and (max-width: 767px) {
      transform: none !important;
    }

    @media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
      transform: none !important;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
      transform: none !important;
    }
  }

  .hover-reveal__inner,
  .hover-reveal__img {
    width: 100%;
    height: 100%;
    position: relative;
    @media all and (max-width: 767px) {
      transform: none !important;
    }
    @media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
      transform: none !important;
    }
    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
      transform: none !important;
    }
  }

  .hover-reveal__img {
    background-size: cover;
    background-position: 50% 50%;
  }

  .cursor {
    display: none;
  }

  .credits {
    align-self: flex-start;
    padding: 5rem 0 0 0;
  }

  .credits a {
    text-decoration: underline;
  }

  @media screen and (min-width: 53em) {
    .frame {
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      background: none;
      height: 50vh;
      pointer-events: none;
      padding-bottom: 0;
    }
    .frame__links a:not(:last-child) {
      margin-right: 0;
    }
    .frame__links {
      margin: 0.5rem 0 2rem;
      justify-self: start;
    }
    .frame__links a {
      margin-left: 0.5rem;
      pointer-events: auto;
    }
    .frame__button {
      cursor: not-allowed;
      pointer-events: auto;
      margin-top: auto;
      transform: translateY(50%);
    }
    .menu {
      padding-top: 0vh;
    }
    .menu__item {
      justify-content: initial;
    }
    .menu__item-text {
      padding: 1.5vw 0;
      font-size: 4vw;
    }
    .menu__item-sub {
      display: flex;
    }
  }

  @media (any-pointer: fine) {
    .cursor {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      pointer-events: none;
    }
    .cursor__inner {
      fill: var(--cursor-fill);
      stroke: var(--cursor-stroke);
      stroke-width: var(--cursor-stroke-width);
      opacity: 0.3;
    }
    .credits {
      padding-left: 25vw;
    }
  }

  html.has-scroll-smooth {
    overflow: hidden;
  }

  html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .has-scroll-smooth body {
    overflow: hidden;
  }

  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh;
  }

  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
  }
  .c-scrollbar:hover {
    transform: scaleX(1.45);
  }
  .c-scrollbar:hover,
  .has-scroll-scrolling .c-scrollbar,
  .has-scroll-dragging .c-scrollbar {
    opacity: 1;
  }

  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}
@keyframes marqueenew {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
