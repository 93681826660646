@import "assets/scss/variable";

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic__sailec_bold-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic__sailec_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic__sailec_hairline-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic__sailec_hairline-webfont.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic__sailec_light-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic__sailec_light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic__sailec_medium-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic__sailec_medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic__sailec_thin-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic__sailec_thin-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic_sailec_black-webfont.woff2")
      format("woff2"),
    url("assets/Fonts/type_dynamic_sailec_black-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "sailec";
  src: url("assets/Fonts/type_dynamic_sailec-webfont.woff2") format("woff2"),
    url("assets/Fonts/type_dynamic_sailec-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "sailec";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background: #f0f0f0;
  color: $primary-color;
  overflow: hidden !important;
}

html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @media all and (max-width: 767px) {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }
}
html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $third-color;
  opacity: 1;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.panel {
  height: 100vh;
}

.line {
  width: 100%;
  height: 8px;
  margin: 0 0 10px 0;
  position: relative;
  display: inline-block;
  background-color: red;
}

.smooth-scroll {
  padding-top: 190px;
  overflow: hidden;
  @media all and (max-width: 767px) {
    padding-top: 130px;
  }
}
